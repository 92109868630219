import {readAsArrayBuffer, readAsDataURL} from './asyncReader.js';
import {getAsset} from './prepareAssets';
import {hexToRGB, isMode, pascalCase} from "./helper";

export async function save(inputs) {
  // ---------------------
  // - Private Functions -
  // ---------------------

  function addToUnflattenedObjects(pageObject, pageIndex) {
    let pageObjects = unflattenedObjects[pageIndex];

    for (let i = 0; i < pageObjects.length; i++) {
      const existingPageObject = unflattenedObjects[i];
      if (!existingPageObject) continue;
      if (existingPageObject.x !== pageObject.x) continue;
      if (existingPageObject.y !== pageObject.y) continue;
      if (existingPageObject.width !== pageObject.width) continue;
      if (existingPageObject.type !== pageObject.type) continue;

      if (existingPageObject.type === 'image') {
        if (existingPageObject.imgSrc !== pageObject.imgSrc) continue;
        if (existingPageObject.height !== pageObject.height) continue;
      }

      if (existingPageObject.type === 'text') {
        if (existingPageObject.lines !== pageObject.lines) continue;
        if (existingPageObject.size !== pageObject.size) continue;
      }
    }

    unflattenedObjects[pageIndex].push(pageObject);
  }

  function convertLinesToTextString(lines, fontSize) {
    const lines_of_text = lines.length;
    let text = '';

    for (let i1 = 0; i1 < lines_of_text; i1++) {
      text += lines[i1];

      if (i1 < lines_of_text - 1) {
        text += '\n';
        if (fontSize === 16) continue;
        let timesToLineBreak = Math.floor(fontSize / 16);
        if (timesToLineBreak > 3) timesToLineBreak--;

        for (let i2 = 0; i2 < timesToLineBreak; i2++) {
          text += '\n';
        }
      }
    }

    return text;
  }

  async function exportPdf(inputs) {
    try {
      const pdfDoc = await inputs.pdfDoc;
      const pdfBytes = await pdfDoc.save();
      const isRotated = inputs.pagesMetadata[0].electrosigRotationMetadata % 180 !== 0;

      if (inputs.isIframe || isMode('esign', 'esign_preorder')) {
        return window.parent.postMessage({
          annotations: inputs.annotations,
          documentId: inputs.documentId,
          fileName: inputs.name,
          isRotated: isRotated,
          notaryLiveSkipApproveStep: inputs.notaryLiveSkipApproveStep,
          objects: JSON.stringify(inputs.objects),
          originalPagesRemaining: inputs.originalPagesRemaining,
          pdfBytes: pdfBytes,
          type: 'pdf-saved',
          unflattenedObjects: inputs.unflattenedObjects,
        }, "*");
      }

      const download = await getAsset('download');
      download(pdfBytes, inputs.name, 'application/pdf');
    } catch (e) {
      console.log('Failed to save PDF.');
      throw e;
    }
  }

  async function handleWritePageObjectsLoop(inputs, pageObject) {
    const pageIndex = inputs.pageIndex;

    if (flattenEdits) {
      inputs.pageObject = pageObject;
      await writeToPage(inputs);
      return;
    }

    if (pageObject.disableEditing) {
      return;
    }

    if (pageObject.type === 'image') {
      pageObject.payload = null; // Fix to make sure image elements save (RA, Nov 2022)
    }

    addToUnflattenedObjects(pageObject, inputs.pageIndex);
  }

  function isJpeg(pageObject) {
    if (isWhiteoutBox(pageObject)) return true;
    if (pageObject.file.type === 'image/jpeg') return true;

    if (inputs.pageObject !== undefined && inputs.pageObject.imgSrc !== undefined) {
      let extension = inputs.pageObject.imgSrc.includes('.')
        ? inputs.pageObject.imgSrc.split('.').at(-1).toLowerCase()
        : null;

      return extension === 'jpg' || extension === 'jpeg';
    }

    return false;
  }

  function isWhiteoutBox(pageObject) {
    return pageObject.type === 'image' && (
      pageObject.imgName === 'add_whiteout_box' || pageObject.imgSrc.includes('whiteout-box')
    );
  }

  // Don't run 'pdfDoc.getForm().flatten()' because it frequently results in fields going missing. We are using pdf-flattener.com instead. (RA, Feb 2023)
  // --
  async function loadPdf(inputs) {
    try {
      let pdfDoc = await PDFLib.PDFDocument.load(
        await readAsArrayBuffer(inputs.pdfFile)
        // {ignoreEncryption: true} // Can be used to load encrypted PDFs, but these files won't be downloadable
      );

      return pdfDoc;
    } catch (e) {
      console.log('Failed to load PDF.');
      throw e;
    }
  }

  async function processPages(inputs) {
    let pdfDoc = await inputs.pdfDoc;

    return pdfDoc.getPages().map(async (page, pageIndex) => {

      // This works, but we have other work to do to connect the dots and make it work with the rest of the code. (RA, Sept 21 2022)
      // page.setRotation(PDFLib.degrees(90));

      await writePageObjects({
        objects: inputs.objects,
        page: page,
        pageIndex: pageIndex,
        pagesMetadata: inputs.pagesMetadata,
        pdfDoc: pdfDoc,
      });
    });
  }

  function drawingObjectPositioning(inputs) {
    const pageRotation = inputs.pageRotation;
    let pageDimensions = inputs.page.getSize();
    let pageObject = inputs.pageObject;

    // If the page is not rotated, page coordinates origin is
    // bottom left, object coordinate origin is bottom left
    let position = {
      x: pageObject.x,
      y: pageDimensions.height - pageObject.y
    };

    if (pageRotation === 90) {
      // If the page is rotated 90 degrees, page coordinate origin is
      // top left, object coordinate origin is top left
      position = {
        x: pageObject.y,
        y: pageObject.x,
      };
    } else if (pageRotation === 180) {
      // If the page is rotated 180 degrees, page coordinate origin is
      // top right, object coordinate origin is bottom left
      position = {
        x: pageDimensions.width - pageObject.x,
        y: pageObject.y,
      };
    } else if (pageRotation === 270) {
      // If the page is rotated 270 degrees, page coordinate origin is
      // bottom right, object coordinate origin is top right
      let xPositionPercentage = pageObject.x / pageDimensions.height;
      let yPositionPercentage = pageObject.y / pageDimensions.width;

      position = {
        x: (
          pageDimensions.width
          - (yPositionPercentage * pageDimensions.width)
        ),
        y: (
          pageDimensions.height
          - (xPositionPercentage * pageDimensions.height)
        ),
      };
    }

    return position;
  }

  function imageObjectPositioning(inputs) {
    const pageRotation = inputs.pageRotation;
    let pageDimensions = inputs.page.getSize();
    let pageObject = inputs.pageObject;

    // If the page is not rotated, page coordinates origin is
    // bottom left, object coordinate origin is bottom left
    let position = {
      x: pageObject.x,
      y: pageDimensions.height - pageObject.y - pageObject.height
    };

    if (pageRotation === 90) {
      // If the page is rotated 90 degrees, page coordinate origin is
      // top left, object coordinate origin is bottom left
      position = {
        x: pageObject.height + pageObject.y,
        y: pageObject.x,
      };
    } else if (pageRotation === 180) {
      // If the page is rotated 180 degrees, page coordinate origin is
      // top right, object coordinate origin is bottom left
      position = {
        x: pageDimensions.width - pageObject.x,
        y: pageObject.y + pageObject.height,
      };
    } else if (pageRotation === 270) {
      // If the page is rotated 270 degrees, page coordinate origin is
      // bottom right, object coordinate origin is bottom left
      let xPositionPercentage = pageObject.x / pageDimensions.height;
      let yPositionPercentage = pageObject.y / pageDimensions.width;

      position = {
        x: (
          pageDimensions.width
          - (yPositionPercentage * pageDimensions.width)
          - pageObject.height
        ),
        y: (
          pageDimensions.height
          - (xPositionPercentage * pageDimensions.height)
        ),
      };
    }

    return position;
  }

  // At angle 270, the coordinates seem to start from the bottom right corner of the document, and changes in y actually change x. You can confirm this yourself using a document with that rotational metadata and manually forcing the x and y to be '50' or '250' here (RA, Sept 2022)
  // --
  function textObjectPositioning(inputs) {
    const pageRotation = inputs.pageRotation;
    let pageDimensions = inputs.page.getSize();
    let pageObject = inputs.pageObject;

    // If the page is not rotated, page coordinates origin is
    // bottom left, object coordinate origin is bottom left
    let position = {
      x: pageObject.x,
      y: pageDimensions.height - pageObject.y - pageObject.size
    };

    if (pageRotation === 90) {
      // If the page is rotated 90 degrees, page coordinate origin is
      // top left, object coordinate origin is bottom left
      position = {
        x: pageObject.y + pageObject.size,
        y: pageObject.x,
      };
    } else if (pageRotation === 180) {
      // If the page is rotated 180 degrees, page coordinate origin is
      // top right, object coordinate origin is bottom left
      position = {
        x: pageDimensions.width - pageObject.x,
        y: pageObject.y + pageObject.size,
      };
    } else if (pageRotation === 270) {
      // If the page is rotated 270 degrees, page coordinate origin is
      // bottom right, object coordinate origin is bottom left
      let lineHeightPercentage = pageObject.size / pageDimensions.height;
      let xPositionPercentage = pageObject.x / pageDimensions.height;
      let yPositionPercentage = pageObject.y / pageDimensions.width;

      position = {
        x: (
          pageDimensions.width
          - (yPositionPercentage * pageDimensions.width)
          - (lineHeightPercentage * pageDimensions.height)
        ),
        y: (
          pageDimensions.height
          - (xPositionPercentage * pageDimensions.height)
        ),
      };
    }

    return position;
  }

  // https://pdf-lib.js.org/docs/api/interfaces/pdfpagedrawsvgoptions#optional-scale
  // --
  async function writeDrawingToPage(inputs) {
    const {x, y, width, originWidth, color, rotation, opacity} = inputs.pageObject;

    const pageInitialRotation = inputs.pagesMetadata[inputs.pageIndex].electrosigInitialRotationMetadata;

    let objectPositioning = drawingObjectPositioning({
      page: inputs.page,
      pageObject: inputs.pageObject,
      pageRotation: pageInitialRotation,
    });

    inputs.page.drawSvgPath(inputs.pageObject.path, {
      x: objectPositioning.x,
      y: objectPositioning.y,
      scale: width / originWidth,
      // color: color,
      rotate: PDFLib.degrees(pageInitialRotation),
      // opacity: opacity,
      borderWidth: (width / originWidth) * 4,
    });
  }

  async function writeImageToPage(inputs) {
    const {type, x, y, width, height, rotation, opacity, isAnnotation} = inputs.pageObject;

    let imgBlob = inputs.pageObject.file;
    let imageArrayBuffer;

    if (imgBlob.constructor.name === 'Blob') {
      imageArrayBuffer = await readAsArrayBuffer(imgBlob);
    } else {
      imageArrayBuffer = await fetch(inputs.pageObject.file.imgSrc).then(
        (response) => response.arrayBuffer()
      );
    }

    let pdfImage;

    if (isJpeg(inputs.pageObject)) {
      pdfImage = await inputs.pdfDoc.embedJpg(imageArrayBuffer);
    } else {
      pdfImage = await inputs.pdfDoc.embedPng(imageArrayBuffer);
    }

    const pageInitialRotation = inputs.pagesMetadata[inputs.pageIndex].electrosigInitialRotationMetadata;

    let objectPositioning = imageObjectPositioning({
      page: inputs.page,
      pageObject: inputs.pageObject,
      pageRotation: pageInitialRotation,
    });

    inputs.page.drawImage(pdfImage, {
      x: objectPositioning.x,
      y: objectPositioning.y,
      width: width,
      height: height,
      rotate: PDFLib.degrees(pageInitialRotation),
      // opacity: opacity,
    });
  }

  async function writePageObjects(inputs) {
    const pageIndex = inputs.pageIndex;
    const allPageObjects = inputs.objects[pageIndex];
    annotations[pageIndex] = [];
    unflattenedObjects[pageIndex] = [];

    // loop through all whiteout objects on the page
    for (let i = 0; i < allPageObjects.length; i++) {
      const pageObject = allPageObjects[i];

      if (isWhiteoutBox(pageObject)) {
        await handleWritePageObjectsLoop(inputs, pageObject);
      }
    }

    // loop through all page objects again to write all other objects
    for (let i = 0; i < allPageObjects.length; i++) {
      const pageObject = allPageObjects[i];

      if (isWhiteoutBox(pageObject)) {
        continue;
      }

      if (pageObject.isAnnotation 
        || pageObject.type === 'actionable-annotation'
      ) {
        delete pageObject.createdFromContextMenu; // This makes sure across-device users can't delete other's annotations. (RA, Jan 2023)
        annotations[pageIndex].push(pageObject);
        continue;
      }

      await handleWritePageObjectsLoop(inputs, pageObject);
    }
  }

  async function writeTextToPage(inputs) {
    const {x, y, width, height, lines, size, fontFamily, color, rotation, opacity, isAnnotation} = inputs.pageObject;

    const pdfFont = await inputs.pdfDoc.embedFont(fontFamily);
    const text = convertLinesToTextString(lines, size);

    // If you change this, make sure to change Text.svelte's textIsPlaceholder as well (RA, Jan 2023)
    if (text === 'New text field...'
      || text === 'New annotation...'
      || text === 'dynamic_field'
    ) {
      return null;
    }

    let pageInitialRotation = inputs.pagesMetadata[inputs.pageIndex].electrosigInitialRotationMetadata;
    let pageRotation = inputs.pagesMetadata[inputs.pageIndex].electrosigRotationMetadata;

    let objectPositioning = textObjectPositioning({
      page: inputs.page,
      pageObject: inputs.pageObject,
      pageRotation: pageInitialRotation,
    });

    if (!pageRotation) pageRotation = 0;

    inputs.page.drawText(text, {
      x: objectPositioning.x,
      y: objectPositioning.y,
      size: size,
      font: pdfFont,
      color: hexToRGB(color === undefined ? '#000000' : color),
      rotate: PDFLib.degrees(pageRotation),
      opacity: opacity,
    });
  }

  async function writeToPage(inputs) {
    if (inputs.pageObject.type === 'actionable-annotation') {
      return;
    }

    const type = inputs.pageObject.type;
    const typePascalCase = pascalCase(type);
    const writeFunction = `write${typePascalCase}ToPage`;

    inputs.pageRotaton = inputs.pagesMetadata[inputs.pageIndex].electrosigInitialRotationMetadata;

    let functionsMap = {
      writeDrawingToPage,
      writeImageToPage,
      writeTextToPage,
    };

    await functionsMap[writeFunction](inputs);
  }

  // --------
  // - Main -
  // --------

  const PDFLib = await getAsset('PDFLib');
  let annotations = [];
  let flattenEdits = inputs.flattenEdits;
  let unflattenedObjects = [];
  let pdfDoc = loadPdf(inputs);

  await Promise.all(await processPages({
    objects: inputs.objects,
    pagesMetadata: inputs.pagesMetadata,
    pdfDoc: pdfDoc,
  }));

  exportPdf({
    annotations: annotations,
    documentId: inputs.documentId,
    isIframe: inputs.isIframe,
    notaryLiveSkipApproveStep: inputs.notaryLiveSkipApproveStep,
    objects: inputs.objects,
    originalPagesRemaining: inputs.originalPagesRemaining,
    name: inputs.name,
    pagesMetadata: inputs.pagesMetadata,
    pdfDoc: pdfDoc,
    unflattenedObjects: unflattenedObjects,
  });
}
