<script>
  import { pannable } from "./utils/pannable.js";
  import IconPenDrawing from "./Icons/IconPenDrawing.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import IconPenPad from "./Icons/IconPenPad.svelte";
  import IconCalendarDay from "./Icons/IconCalendarDay.svelte";
  import { isMode } from "./utils/helper";
  import IconSmallX from "./Icons/IconSmallX.svelte";
  import IconSmallCheck from "./Icons/IconSmallCheck.svelte";

  export let associatedObjectId = null;
  export let color = 'red';
  export let frozen = false;
  export let hidden = false;
  export let id;
  export let pageIndex;
  export let pageScale;
  export let participant;
  export let type = 'signature';
  export let x = 200;
  export let y = 250;

  export let width = 190;
  export let height = 60;

  let xResize = 0;
  let yResize = 0;

  const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');

  let resizing = false;
  let resizeStartX;
  let resizeStartY;
  let initialWidth;
  let initialHeight;
  let lockAspectRatio = true;
  let aspectRatio;

  const dispatch = createEventDispatcher();

  let _dialogXPosition = 0;
  let editable;
  let dx = 0;
  let dy = 0;
  let initialX = x;
  let initialY = y;
  let startX;
  let startY;
  let moving = false;
  let hasMoved = false;

  let minWidth = 80;  
  let minHeight = 30;  
  let textElement;

  const colors = {
    red: {
      hex: '#F56565',
      rgb: '245, 101, 101',
    },
  };

  onMount(() => {
    aspectRatio = width / height;
    updateMinDimensions();
  });

  function updateMinDimensions() {
    if (textElement) {
      const textRect = textElement.getBoundingClientRect();
      minWidth = Math.max(80, textRect.width + 10);  // Update the minimum width but keep it lower.
      minHeight = Math.max(30, textRect.height + 5);  // Update the minimum height but allow for a smaller box.
    }
  }

  function getFirstNameAndInitial(fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    } else if (words.length > 1) {
      const firstName = words[0];
      const lastNameInitial = words[words.length - 1][0];
      return `${firstName} ${lastNameInitial}.`;
    } else {
      return '';
    }
  }

  function handleClick() {
    if (isMode('ipen') && hasMoved) return null;

    dispatch('click', {
      objectId: id,
      pageIndex,
      pageScale,
      participant,
      type,
      x,
      y: y + 24,
      width,
      height,
    });
  }

  function handleDelete(event) {
    event.stopPropagation(); // Stop propagation to prevent drag
    dispatch('delete', { id });
  }

  function handlePanEnd(event) {
    x += dx;
    y += dy;

    dispatch('update', { x, y });

    dx = 0;
    dy = 0;
    moving = false;
    
    if (!hasMoved) {
      handleClick();
    }
  }

  function handlePanMove(event) {
    if (frozen) return;

    dx = (event.detail.x - startX) / pageScale;
    dy = (event.detail.y - startY) / pageScale;

    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
      hasMoved = true;
    }
  }

  function handlePanStart(event) {
    const target = event.target.closest('.resize-handle, .delete-button');

    if (target) {
      moving = false;
      return;
    }

    startX = event.detail.x;
    startY = event.detail.y;
    moving = true;
    hasMoved = false;
  }

  function handleResizeStart(event) {
    event.preventDefault();
    event.stopPropagation(); // Stop propagation to prevent drag
    resizing = true;
    resizeStartX = event.clientX;
    resizeStartY = event.clientY;
    initialWidth = width;
    initialHeight = height;
    aspectRatio = height / width;
    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }

  function handleResizeMove(event) {
    if (!resizing) return;
    xResize = (event.clientX - resizeStartX) / pageScale;
    yResize = (event.clientY - resizeStartY) / pageScale;

    let newWidth = initialWidth + xResize;
    let newHeight = initialHeight + yResize;

    // Enforce lower minimum resizing limits
    width = Math.max(minWidth, Math.min(500, newWidth));
    height = Math.max(minHeight, Math.min(300, newHeight));

    if (lockAspectRatio) {
      height = width * aspectRatio;
    }

    updateMinDimensions();  // Recalculate min dimensions after resizing
  }

  function handleResizeEnd() {
    resizing = false;
    window.removeEventListener('mousemove', handleResizeMove);
    window.removeEventListener('mouseup', handleResizeEnd);
    dispatch('update', { width, height });
  }

  $: associatedObjectId, dispatch('actionPerformed', {
    actionPerformed: associatedObjectId !== null,
    objectId: id,
    pageIndex,
    participant,
  });
</script>

<svelte:options immutable={true} />

<div
  id="actionable-annotation-{pageIndex}-{id}"
  class="absolute left-0 top-0 select-none"
  class:hidden={hidden}
  class:cursor-grab={!frozen && !moving}
  class:cursor-grabbing={!frozen && moving}
  style="z-index: -1; transform-origin: top left; transform: translate({x + dx}px, {y + dy}px);"
  use:pannable
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  bind:this={editable}
  on:pointerup={handleClick}

>

<div class="absolute" style="transform:translateX(-50%); left:50%; color:white; top:-10px;">
  {#if isMode('esign_preorder', 'preorder', 'cna_preorder') && !associatedObjectId !== null || isMode('ipen')}
  <span on:pointerup={handleDelete}
    class="delete-button flex justify-center items-center cursor-pointer bg-white rounded w-4 h-4"
    style="background-color: {colors[color].hex}; top: 0.125rem; right: 0.125rem; border-radius:10px;"
  >
    <IconSmallX />
  </span>
  {/if}

  {#if associatedObjectId !== null}
  <span class="absolute flex justify-center items-center bg-white rounded w-4 h-4"
    style="color: {colors[color].hex}; top: 0.125rem; right: 0.125rem; border-radius: 10px; transform:translateX(-50%); left:50%; "
  >
    <IconSmallCheck />
  </span>
  {/if}
</div>

  {#if type === 'date'}
    <div
      class="items-center border-2 rounded-b-md rounded-tr-md gap-1 overflow-hidden"
      style="background-color: rgba({colors[color].rgb}, 0.1); border-color: rgb({colors[color].rgb}); color: {colors[color].hex}"
    >
      <div
        class="flex justify-center items-center"
      >
        <select
          class="px-2 py-1 bg-transparent border-none outline-none"
          style="max-width: 112px;"
        >
          <option value="default">09/08/23</option>
          <option value="formatted">Sep. 8, 2023</option>
        </select>
        <button
          class="px-3 py-1 text-white ml-2"
          style="background-color: {color}"
        >
          <span>Place</span>
        </button>
      </div>
    </div>
  {:else}
  <div
    class="flex justify-center items-center border-2 rounded gap-1 resize-transition"
    style="width: {width}px; height: {height}px; background-color: rgba({colors[color].rgb}, 0.15); border-color: rgba({colors[color].rgb}, 0.3); color: {colors[color].hex}"
  >
    <div class="flex justify-center items-center">
      <span 
        bind:this={textElement} 
        class="text-center break-words"
        style="white-space: normal; overflow-wrap: break-word; word-break: break-word; overflow: hidden;"
      >
        {#if type === 'signature'}
          {getFirstNameAndInitial(participant.full_name)} Sign Here
        {:else if type === 'initial'}
          {getFirstNameAndInitial(participant.full_name)} Initial Here
        {/if}
      </span>
    </div>

    {#if isPreorderMode || isMode('ipen')}
      <div class="resize-handle" on:mousedown|stopPropagation={handleResizeStart}></div>
    {/if}
  </div>
  {/if}
</div>


<style>
  .resize-handle {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    background-color: rgb(245, 101, 101);
    cursor: se-resize;
    border-radius: 50%;
  }

  .resize-transition {
    transition: width 0.1s ease, height 0.1s ease;
  }

</style>