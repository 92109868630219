<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "./utils/pannable.js";
  import { tapout } from "./utils/tapout.js";

  export let disableEditing;
  export let file;
  export let height;
  export let imgName;
  export let isWhiteoutBox;
  export let initialHeight;
  export let initialWidth;
  export let isFocusedProp = false;
  export let pageScale = 1;
  export let payload;
  export let width;
  export let x;
  export let y;

  const dispatch = createEventDispatcher();
  let startX;
  let startY;
  let canvas;
  let isFocused = isFocusedProp;
  let isWhiteoutBoxFallback;
  let operation = "";
  let directions = [];
  let dx = 0;
  let dy = 0;
  let dw = 0;
  let dh = 0;
  let aspectRatio = 0;
  let lockAspectRatio = !['add_whiteout_box'].includes( imgName );

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  function checkForWhiteoutBox() {
    return isWhiteoutBox
      || imgName === 'add_whiteout_box'
      || file.isWhiteoutBox
      || file.imgName === 'add_whiteout_box'
      || (file.imgSrc && file.imgSrc.includes('whiteout-box'))
      || (payload.imgSrc && payload.imgSrc.includes('whiteout-box'));
  }

  function handleClick(event) {
    isFocused = true;
  }

  function handlePanEnd(event) {
    if (operation === "move") {
      dispatch("update", {
        x: x + dx,
        y: y + dy
      });

      dx = 0;
      dy = 0;
    } else if (operation === "scale") {
      let finalWidth = width + dw;
      let finalHeight = height + dh;

      if (lockAspectRatio) {
        finalWidth = finalHeight / aspectRatio;
        finalHeight = finalWidth * aspectRatio;
      }

      dispatch("update", {
        x: x + dx,
        y: y + dy,
        width: finalWidth,
        height: finalHeight
      });

      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      directions = [];
    }

    operation = "";
  }

  function handlePanMove(event) {
    if (disableEditing) {
      return;
    }

    const _dx = (event.detail.x - startX) / pageScale;
    const _dy = (event.detail.y - startY) / pageScale;

    if (operation === "move") {
      dx = _dx;
      dy = _dy;
    } else if (operation === "scale") {
      if (directions.includes("left")) {
        dx = _dx;
        dw = -_dx;
      }
      if (directions.includes("top")) {
        dy = _dy;
        dh = -_dy;
      }
      if (directions.includes("right")) {
         dw = _dx;
      }
      if (directions.includes("bottom")) {
         dh = lockAspectRatio ? _dx * aspectRatio : _dy;
      }
    }
  }

  function handlePanStart(event) {
    startX = event.detail.x;
    startY = event.detail.y;
    if (event.detail.target === event.currentTarget) {
      return (operation = "move");
    }
    operation = "scale";
    directions = event.detail.target.dataset.direction.split("-");
  }

  function onDelete() {
    dispatch("delete");
  }

  // 'limit' and 'scale' here seem to be the key to setting the image's default size correctly. We don't want to just use 'height' and 'width', because changing those causes the image to be cutoff on the right and bottom. (RA, Aug 2022)
  // --
  async function render() {
    let limit = 500;

    if (initialWidth) {
      limit = initialWidth;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(payload, 0, 0);
    let scale = 1;

    if (width > limit) {
      scale = limit / width;
    }

    if (height > limit) {
      scale = Math.min(scale, limit / height);
    }

    let img_height = height * scale;

    if (initialHeight) {
      img_height = initialHeight;
    }

    aspectRatio = height / width;

    isWhiteoutBoxFallback = checkForWhiteoutBox();

    dispatch("update", {
      width: width * scale,
      height: img_height,
    });

    if (!['image/jpeg', 'image/png', 'image'].includes(file.type)) {
      canvas.toBlob(blob => {
        dispatch("update", {
          file: blob
        });
      });
    }
  }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(render);
</script>

<style>
  .operation {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .resize-border {
    @apply absolute border-dashed border-gray-600;
  }

  .resize-corner {
    @apply absolute bg-blue-300 h-7 rounded-full w-7;
  }
</style>

<svelte:options immutable={true} />
<div
  class="absolute left-0 top-0 select-none"
  on:pointerdown={event => handleClick(event)}
  use:tapout
  on:tapout={event => isFocused = false}
  style="
    width: {width + dw}px; 
    height: {height + dh}px; 
    transform: translate({x + dx}px,
    {y + dy}px); 
    {isWhiteoutBox || isWhiteoutBoxFallback ? 'z-index: -1;' : ''}
  "
>
  {#if !isWhiteoutBox || !isWhiteoutBoxFallback}
    <div
        class:opacity-100={isFocused}
        class:opacity-15={!isFocused}
        class="absolute border-2 border-blue-500 rounded-lg pointer-events-none"
        style="top: -4px; right: -4px; bottom: -4px; left: -4px;"
    ></div>
  {/if}

  <div
    use:pannable
    on:panstart={handlePanStart}
    on:panmove={handlePanMove}
    on:panend={handlePanEnd}
    class="absolute w-full h-full"
    class:cursor-grab={!disableEditing}
    class:cursor-grabbing={!disableEditing && operation === 'move'}
    class:operation
  >
    {#if !disableEditing && imgName === "add_whiteout_box" && isFocused}
      <div
        data-direction="left"
        class="resize-border h-full w-1 left-0 top-0 border-l cursor-ew-resize" />
      <div
        data-direction="top"
        class="resize-border w-full h-1 left-0 top-0 border-t cursor-ns-resize" />
      <div
        data-direction="bottom"
        class="resize-border w-full h-1 left-0 bottom-0 border-b cursor-ns-resize" />
      <div
        data-direction="right"
        class="resize-border h-full w-1 right-0 top-0 border-r cursor-ew-resize" />
      <div
        data-direction="left-top"
        class="resize-corner left-0 top-0 cursor-nwse-resize transform
        -translate-x-1/2 -translate-y-1/2 scale-50" />
      <div
        data-direction="right-top"
        class="resize-corner right-0 top-0 cursor-nesw-resize transform
        translate-x-1/2 -translate-y-1/2 scale-50" />
      <div
        data-direction="left-bottom"
        class="resize-corner left-0 bottom-0 cursor-nesw-resize transform
        -translate-x-1/2 translate-y-1/2 scale-50" />
    {/if}

    {#if !disableEditing && isFocused}
      <div
        data-direction="right-bottom"
        class="resize-corner right-0 bottom-0 cursor-nwse-resize transform translate-x-1/2 translate-y-1/2 scale-50" />
    {/if}
  </div>

  {#if !disableEditing && isFocused}
    <div
      on:pointerup={onDelete}
      class="absolute bg-white cursor-pointer h-7 left-0 m-auto md:h-12 md:scale-25 md:w-12 opacity-50 right-0 rounded-full top-0 transform -translate-y-1/2 w-7"
    >
      <img class="w-full h-full" src="/delete.svg" alt="delete object" />
    </div>
  {/if}

  <canvas class="w-full h-full" bind:this={canvas} style="aspect-ratio: initial" />
</div>
